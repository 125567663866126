import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useEffect, useState } from "react";
import Loader from "react-loaders";
import i18n from "i18next";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { socket } from "../../../src/socket/socket";
import { API_URL } from "../../config/config";
import AllApi from "../../Service/services";

const Login = lazy(() => import("../../Pages/UserPages/LoginBoxed/login"));
const ForgotPassword = lazy(() =>
  import("../../Pages/UserPages/ForgotPasswordBoxed/forgotPassword")
);
const ResetPassword = lazy(() =>
  import("../../Pages/UserPages/ResetPassword/resetPassword")
);
const Dashboards = lazy(() => import("../../Pages/Dashboards/dashboards"));
const Machinery = lazy(() =>
  import("../../Pages/MachineryAdmin/MachineryAdmin")
);
const OEMUsers = lazy(() => import("../../Pages/OemUsers/OemUsers"));
const Register = lazy(() =>
  import("../../Pages/UserPages/RegisterBoxed/register")
);
const VerifyAccount = lazy(() =>
  import("../../Pages/UserPages/VerifyAccount/verifyAccount")
);
const PrivacyPolicy = lazy(() =>
  import("../../Pages/UserPages/StaticPages/PrivacyPolicy")
);
const TermsOfUse = lazy(() =>
  import("../../Pages/UserPages/StaticPages/TermsOfUse")
);
// const Call = lazy(() => import('../../Pages/Dashboards/Call/Call'));

// import Login from '../../Pages/UserPages/LoginBoxed/login';
// import ForgotPassword from '../../Pages/UserPages/ForgotPasswordBoxed/forgotPassword';
// import ResetPassword from '../../Pages/UserPages/ResetPassword/resetPassword';
// import Dashboards from '../../Pages/Dashboards/dashboards';
// import Machinery from '../../Pages/MachineryAdmin/MachineryAdmin';
// import OEMUsers from '../../Pages/OemUsers/OemUsers';
// import Register from '../../Pages/UserPages/RegisterBoxed/register';
// import VerifyAccount from '../../Pages/UserPages/VerifyAccount/verifyAccount';
// import PrivacyPolicy from '../../Pages/UserPages/StaticPages/PrivacyPolicy';
// import TermsOfUse from '../../Pages/UserPages/StaticPages/TermsOfUse';

const AppMain = (props) => {
  const history = useHistory();
  const location = useLocation();
 

   useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === "US" || !lang) {
      let newQueryParamString = "";
      if (location.pathname === "/") {
        history.push(`/login${newQueryParamString}`);
        i18n.changeLanguage("en");
      } else {
        history.push(`${location.pathname}${newQueryParamString}`);
        i18n.changeLanguage("en");
      }
    } else {
      let newQueryParamString = "?lang=pt";
      if (location.pathname === "/") {
        if (localStorage.getItem("token")) {
          history.push("/dashboard/main?lang=pt");
        } else {
          history.push("/login?lang=pt");
        }
        i18n.changeLanguage("pt");
      } else {
        history.push(`${location.pathname}${newQueryParamString}`);
        i18n.changeLanguage("pt");
      }
    }
  }, []);

  return (
    <Fragment>
      {/* Login Page */}
{/* 
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/login" component={Login} />
      {/* </Suspense> */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/privacy-policy" component={PrivacyPolicy} />
      {/* </Suspense> */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/terms-of-use" component={TermsOfUse} />
      {/* </Suspense> */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/register" component={Register} />
      {/* </Suspense> */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/verify-account" component={VerifyAccount} />
      {/* </Suspense> */}

      {/* Forgot password Page */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/forgot-password" component={ForgotPassword} />
      {/* </Suspense> */}

      {/* Reset password Page */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/reset-password" component={ResetPassword} />
      {/* </Suspense> */}

      {/* Dashboards */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-beat" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/dashboard" component={Dashboards} />
      {/* </Suspense> */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-beat" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/dashboard" component={Machinery} />
      {/* </Suspense> */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-beat" />
              </div>
            </div>
          </div>
        }
      > */}
        <Route path="/dashboard" component={OEMUsers} />
      {/* </Suspense> */}

      {/* <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/call/:id" component={Call} />
            </Suspense> */}

      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
